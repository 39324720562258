<template>
  <ButtonPlay
    outline
    size="sm"
    @click="$router.push({ path: 'config_curso/' + courseId })"
  >
    <span>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask
          id="mask0_4594_25957"
          style="mask-type: alpha"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="20"
          height="20"
        >
          <path
            d="M9.99992 15.1668H5.99992C2.37992 15.1668 0.833252 13.6202 0.833252 10.0002V6.00016C0.833252 2.38016 2.37992 0.833496 5.99992 0.833496H7.33325C7.60659 0.833496 7.83325 1.06016 7.83325 1.3335C7.83325 1.60683 7.60659 1.8335 7.33325 1.8335H5.99992C2.92659 1.8335 1.83325 2.92683 1.83325 6.00016V10.0002C1.83325 13.0735 2.92659 14.1668 5.99992 14.1668H9.99992C13.0733 14.1668 14.1666 13.0735 14.1666 10.0002V8.66683C14.1666 8.3935 14.3933 8.16683 14.6666 8.16683C14.9399 8.16683 15.1666 8.3935 15.1666 8.66683V10.0002C15.1666 13.6202 13.6199 15.1668 9.99992 15.1668Z"
            fill="#292D32"
          />
          <path
            d="M5.66664 11.7934C5.25997 11.7934 4.88664 11.6467 4.61331 11.38C4.28664 11.0534 4.14664 10.58 4.21997 10.08L4.50664 8.07337C4.55997 7.68671 4.81331 7.18671 5.08664 6.91337L10.34 1.66004C11.6666 0.333372 13.0133 0.333372 14.34 1.66004C15.0666 2.38671 15.3933 3.12671 15.3266 3.86671C15.2666 4.46671 14.9466 5.05337 14.34 5.65337L9.08664 10.9067C8.8133 11.18 8.3133 11.4334 7.92664 11.4867L5.91997 11.7734C5.83331 11.7934 5.74664 11.7934 5.66664 11.7934ZM11.0466 2.36671L5.7933 7.62004C5.66664 7.74671 5.51997 8.04004 5.4933 8.21337L5.20664 10.22C5.17997 10.4134 5.21997 10.5734 5.31997 10.6734C5.41997 10.7734 5.57997 10.8134 5.7733 10.7867L7.77997 10.5C7.9533 10.4734 8.2533 10.3267 8.3733 10.2L13.6266 4.94671C14.06 4.51337 14.2866 4.12671 14.32 3.76671C14.36 3.33337 14.1333 2.87337 13.6266 2.36004C12.56 1.29337 11.8266 1.59337 11.0466 2.36671Z"
            fill="#292D32"
          />
          <path
            d="M13.2333 6.55319C13.1866 6.55319 13.14 6.54652 13.1 6.53319C11.3466 6.03986 9.95329 4.64652 9.45996 2.89319C9.38662 2.62652 9.53996 2.35319 9.80662 2.27319C10.0733 2.19986 10.3466 2.35319 10.42 2.61986C10.82 4.03986 11.9466 5.16652 13.3666 5.56652C13.6333 5.63986 13.7866 5.91985 13.7133 6.18652C13.6533 6.41319 13.4533 6.55319 13.2333 6.55319Z"
            fill="#292D32"
          />
        </mask>
        <g mask="url(#mask0_4594_25957)">
          <rect width="16" height="16" fill="#9E9E9E" />
        </g>
      </svg>
    </span>
    {{ $t('empty_state.edit_button') }}
  </ButtonPlay>
</template>

<script>
export default {
  props: {
    courseId: {
      type: Number,
    },
  },
};
</script>

<style></style>
